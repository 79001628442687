import React from 'react'
import { loginOptions } from '@constants/login-options'
import { SmallButton } from '@shared/components/small-button'
import cx from 'classnames'

import { IStateProps } from './model'

import styles from './style.module.scss'

export const View = ({
	showDropdown,
	toggleDropdownVisible,
	ref,
	onAnchorClick,
	className,
}: IStateProps) => (
	<div className={cx(styles.wrap, className)}>
		<SmallButton
			data-gtm-element='header-enter-button-click'
			disabled={showDropdown}
			onClick={toggleDropdownVisible}
		>
			Вход
		</SmallButton>
		{showDropdown && (
			<div ref={ref} className={styles.dropdown}>
				{loginOptions.map(({ name, path }, i) => (
					<a
						key={i}
						onClick={onAnchorClick}
						href={path}
						rel='noopener noreferrer nofollow'
						target='_blank'
					>
						{name}
					</a>
				))}
			</div>
		)}
	</div>
)
