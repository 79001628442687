import React from 'react'
import { loginOptions } from '@constants/login-options'
import cx from 'classnames'

import { IStateProps } from './model'

import styles from './style.module.scss'

export const View = ({ className, linksClassName }: IStateProps) => {
	return (
		<div className={cx(styles.wrap, className)}>
			<div className={styles.title}>Войти в личный кабинет</div>
			<div className={cx(styles.links, linksClassName)}>
				{loginOptions.map(({ name, path }: { name: string; path: string }, idx: number) => (
					<span key={idx}>
						<a
							href={path}
							className={styles.link}
							rel='noopener noreferrer nofollow'
							target='_blank'
						>
							{name}
						</a>{' '}
						{idx + 1 !== loginOptions.length && '|'}
					</span>
				))}
			</div>
		</div>
	)
}
