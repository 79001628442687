import React from 'react'
import { topMenuItems } from '@shared/menu-items/top-menu-items-v2'
import ArrowSVG from '@shared/svg/arrow.svg'
import { isClient } from '@shared/utils/is-client'
import cx from 'classnames'
import Link from 'next/link'

import { IStateProps } from './model'
import { TopSubmenuV2 } from './submenu'

import styles from './style.module.scss'

export const View = ({ activeItem, setActiveItem, pathname = '', className }: IStateProps) => {
	return (
		<nav className={styles.nav}>
			{topMenuItems.map(({ path, name, items }, i) => {
				const hasSubmenu = !!items?.length
				const showSubmenu = activeItem === name

				const dataAttr = hasSubmenu
					? {
							'data-submenu-active': showSubmenu,
					  }
					: {}

				const isActiveRoute = (path) => path === `/${pathname.split('/')[1]}`

				return (
					<div key={i} {...dataAttr} className={cx(styles.navItemWrap, className)}>
						<div className={styles.navItem}>
							{hasSubmenu ? (
								<Link
									href={path!}
									role='menubutton'
									onMouseEnter={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
										e.preventDefault()
										setActiveItem(name)
									}}
								>
									<span
										className={cx(styles.navWithSubmenu, {
											[styles.navActive]: isActiveRoute(path),
										})}
									>
										{name}
										<ArrowSVG className={styles.arrow} />
									</span>
								</Link>
							) : (
								<Link
									href={path!}
									className={cx({
										[styles.navActive]: isActiveRoute(path),
									})}
									target={name === 'Банкам' ? '_blank' : '_self'}
									rel={name === 'Банкам' ? 'noopener noreferrer nofollow' : undefined}
								>
									{name}
								</Link>
							)}
						</div>
						{showSubmenu && <TopSubmenuV2 setActiveItem={setActiveItem} items={items} />}
						{!isClient && hasSubmenu && (
							<nav hidden>
								{items!.map(({ name, path }, j) => (
									<a key={j} href={path}>
										{name}
									</a>
								))}
							</nav>
						)}
					</div>
				)
			})}
		</nav>
	)
}
